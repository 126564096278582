<template>
    <div v-if="stepsData" class="@w-full @mt-0 @m-auto songBuilderPage page @pt-8 mobileContainerForDesktop">
        <CircleStudios :circle-text="circleText" :gif="gif"/>
        <form ref="slideForm" :class="{isSubmitted}" class="!@contents" style="display: contents;" @submit.prevent>
            <div class="@w-full">
                <!--Fix for splider on hash-->
                <Slider v-bind="sliderOptions" id="songBuilderSlider" ref="slider" class="autoHeightFix">
                    <SplideSlide v-for="(step, i) in stepsData" :key="i" :data-splide-hash="step.step_slug" :data-index="i" class="@px-2.5">
                        <SongBuilderSlide :ref="step.step_slug" :step="step"/>
                    </SplideSlide>
                </Slider>
            </div>
            <div
                ref="controlPanel"
                class="@mt-auto @w-full @mb-10 @max-w-375 @mx-auto @flex controlPanel @overflow-hidden @px-2.5 @pt-8"
            >
                <button
                    class="mkButton beige invertBorder noHoverMob noMinWidth @mr-2"
                    @click.prevent="$store.commit('songBuilder/slideBack')"
                >
                    Back
                </button>
                <NextButton :active-index="activeStepIdx" :class="{disabledIfInvalid: isLast}" @click.prevent="goNext"/>
            </div>
        </form>
    </div>
</template>

<script>
    import {URLHash} from "@splidejs/splide-extension-url-hash";
    import AdjustableHeight from "@shared/plugins/slider/adjustable_height";
    import SongBuilderSlide from "../components/song_builder/SongBuilderSlide";
    import NextButton from "@songfinch/studios/brachs/components/song_builder/NextSlideButton";
    import CircleStudios from "@songfinch/studios/brachs/components/song_builder/CircleStudios";
    import sliderValidity from "@songfinch/studios/brachs/helpers/slider_validity";

    export default {
        name: "SongBuilder",
        components: {SongBuilderSlide, NextButton, CircleStudios},
        mixins: [sliderValidity],
        data() {
            return {
                activeStepIdx: null,
                isSubmitted: false,
                isLast: null,
                lastTrackedStepSlug: null,
                splideSlideClass: "splide__slide",  // Taken from splide js template
                sliderOptions: {
                    arrows: false,
                    options: {
                        rewind: false,
                        focus: "center",
                        trimSpace: false,
                        padding: 104,
                        breakpoints: {
                            767: {
                                padding: 0,
                            }
                        }
                    },
                    events: {
                        "splide:move": (splide, newIndex) => {
                            //Update state tracking and store where we are coming from
                            const previousIndex = this.activeStepIdx;
                            //Update tracking state variables
                            this.activeStepIdx = newIndex;
                            this.$store.commit("songBuilder/saveSongData");
                            //Track only if we are moving forward
                            newIndex > previousIndex && this.trackSongbuilderStepComplete(previousIndex);
                        },
                        "splide:mounted": () => {
                            const hash = window.location.hash.replace("#", "");
                            this.activeStepIdx = +this.getSlideElByHash(hash)?.dataset.index || 0;
                        },
                        "splide:dragging": () => {
                            this.$refs.slider.$el.querySelector(":focus")?.blur();
                        }
                    },
                    extensions: {
                        URLHash,
                        AdjustableHeight
                    }
                },
                stepNameToIndexMap: null,
            };
        },
        computed: {
            gif() {
                return this.$store.state.cms.data?.songBuilder?.circle;
            },
            stepsData() {
                return this.$store.state.cms.data?.songBuilder?.steps;
            },
            songData() {
                return this.$store.state.songBuilder?.songData;
            },
            stepCount() {
                return this.stepsData?.length || 0;
            },
            circleText() {
                return this.$store.state.songBuilder.circleText || this.$store.state.cms.data?.songBuilder?.circle_text || "Custom Song";
            }
        },
        watch: {
            activeStepIdx(val) {
                this.isLast = val === this.stepsData?.length - 1;
            },
        },
        mounted() {
            //Dynamically populate info about child steps (name => index)
            this.stepNameToIndexMap = Array.from(this.stepsData)
                .map((sd, idx) => [sd.step_slug, idx])
                .reduce((acc, obj) => {
                    acc[obj[0]] = obj[1];
                    return acc;
                }, {});
        },
        methods: {
            goNext() {
                if (!this.$refs.slideForm) return;
                if (this.isLast) {
                    this.isSubmitted = true;
                    const invalidField = this.$refs.slideForm.querySelector(":invalid");
                    if (invalidField) {
                        //If form is invalid, we cannot "submit"
                        return this.gotoSlideWithError(this.$refs.slider, invalidField);
                    }
                    this.$store.commit("songBuilder/saveSongData");
                    this.trackSongbuilderStepComplete(this.stepCount - 1);
                    this.$router.push({name: "StoryBuilder"});
                } else {
                    this.$refs.slider.nextSlide();
                }
            },
            getSlideElByHash(hash) {
                return this.$refs.slideForm.querySelector(
                    `.${this.splideSlideClass}[data-splide-hash="${hash}"]`
                );
            },
            /**
             * Called when step slider is changed. Stores state on last step reported
             * to debounce swiping & button transition events to report doubling
             * @param step_idx
             */
            trackSongbuilderStepComplete(step_idx) {
                const step = this.stepsData[step_idx];
                if (step && step.step_slug !== this.lastTrackedStepSlug) {
                    this.lastTrackedStepSlug = step.step_slug;
                    this.$customEvent("_sf_songbuildersubmit", step.question);
                }
            },
        }
    };
</script>
