import router from "@songfinch/studios/brachs/router";

export default {
    namespaced: true,
    state: {
        songStorageName: `sf_song_data_${window.appSettings.affiliate}`,
        songData: null,
        circleText: ""
    },
    getters: {
        getCheckoutData(state) {
            const songData = state.songData;

            return {
                studio: false,
                gifter_email: songData.gifter_email,
                mention_occasion: songData.mention_occasion || "No",
                mention_recipient: songData.mention_recipient || "No",
                occasion: songData.occasion || window.appSettings.affiliate,
                recipient: songData.recipient,
                for_someone_else: songData.for_someone_else?.id || "No",
                genre: songData.genre.id,
                gender: songData.gender?.id || "3", //No preference
                moods: songData.moods.map(m => m.id),
                questions: [
                    {key: "message", question: "What’s the message?", answer: songData.message_question},
                    ...songData.questions.filter(q => q.answer)
                ],
                pronunciations: songData.pronunciations
            };
        }
    },
    mutations: {
        setTextCircle(state, val) {
            state.circleText = val;
        },
        saveSongData(state) {
            localStorage.setItem(state.songStorageName, JSON.stringify(state.songData));
        },
        resetSongData(state, save) {
            state.songData = {};
            if (save) this.commit("songBuilder/saveSongData");
        },
        initSongData(state) {
            this.commit("songBuilder/resetSongData");
            const savedSongData = localStorage.getItem(state.songStorageName);
            if (savedSongData) {
                state.songData = {...state.songData, ...JSON.parse(savedSongData)};
            }
        },
        slideBack() {
            if (router.currentRoute.value.name === "SongBuilder") {
                const hash = window.location.hash.replace("#", "");
                const newHash = document.querySelector(`.splide__slide[data-splide-hash="${hash}"]`)?.previousElementSibling?.dataset.splideHash; //Finding previous slide hash
                if (newHash) {
                    return window.location.hash = newHash;
                } else {
                    return router.push({name: "Redeem"});
                }
            }

            history.back();
        }
    }
};
