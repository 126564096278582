<template>
    <div class="w-100">
        <div v-if="story">
            <StorySongComplete v-if="story.song" :story="story"/>
            <StoryInProgress v-else :story="story"/>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import StoryInProgress from "@songfinch/studios/brachs/components/story_complete/StoryInProgress";
    import StorySongComplete from "@songfinch/studios/brachs/components/story_complete/StorySongComplete";

    export default {
        name: "Story",
        components: {StoryInProgress, StorySongComplete},
        data() {
            return {
                story: null,
            };
        },
        async created() {
            try {
                const storiesPath = `/stories/${this.$route.params.slug}`;
                const res = await axios.get(storiesPath);
                this.story = res.data?.data;
                if (!this.story.affiliate) {
                    this.story = null;
                    location.href = storiesPath;
                }
            } catch (e) {
                location.href = "/404";
            }
        }
    };
</script>
