export default {
    data() {
        return {
            timeOutGoNext: null,
        };
    },
    methods: {
        gotoSlideWithError(slider, field, timeout = 600) {
            field.focus({preventScroll: true});
            const slide = field?.closest(".splide__slide");
            slider.goto(+slide?.dataset.index);
            this.timeOutGoNext = setTimeout(() => {
                field.reportValidity();
            }, timeout);
        },
    }
};
