import modal from "@songfinch/shared/plugins/modal/index";

export default {
    namespaced: true,
    actions: {},
    mutations: {
        showModal(state, payload) {
            modal.sfModal(payload);
        }
    }
};
