import app from "@songfinch/studios/brachs/app.js";
import router from "@songfinch/studios/brachs/router";
import axios from "axios";
import error_handler from "@shared/helpers/axios_error_handler";

export default {
    namespaced: true,
    state: {
        user: null,
        redirectQuery: {},
        referralCode: null,
    },
    mutations: {
        setUser(state, val) {
            state.user = val || null;
            if (state.user) {
                this.commit("auth/calcCreditsTotal");
            }
        },
        setResponseToken(state, response) {
            axios.defaults.headers.common["X-CSRF-Token"] = response.headers["x-csrf-token"];
        },
        redirectToUserPage(state) {
            if (!state.user) return;
            if (router.currentRoute.value.meta.skipAuthRedirect || state.redirectQuery.skipRedirect) {
                return false;
            }
            if (state.redirectQuery?.forward_to) {
                return window.location.href = state.redirectQuery.forward_to;
            }
            if (state.redirectQuery?.push_to) {
                return router.push({name: state.redirectQuery.push_to, ...JSON.parse(state.redirectQuery.settings)});
            }
            if (state.redirectQuery?.push_to_path) {
                return router.push(state.redirectQuery?.push_to_path);
            }
            switch (state.user.role) {
            case "admin":
                window.location.href = "/admin";
                break;
            case "artist":
                window.location.href = "/artist-admin";
                break;
            default:
                return router.push({name: "Dashboard"});
            }
        },
        setRedirectQuery(state, val) {
            state.redirectQuery = val;
        },
        storeAddresses(state, val) {
            const savedData = localStorage.getItem("sf_addresses");
            const data = savedData ? JSON.parse(savedData) : {};
            const type = val.shipping ? "shipping" : "billing";
            data[`${type}_id`] = val?.address?.id;
            localStorage.setItem("sf_addresses", JSON.stringify(data));
        },
        setReferralCode(state, val) {
            state.referralCode = val;
            if (val) {
                localStorage.setItem("sf_customer_referral_code", val);
            } else {
                localStorage.removeItem("sf_customer_referral_code");
            }
        },
        checkForReferralCode(state) {
            state.referralCode = localStorage.getItem("sf_customer_referral_code") || null;
        },
        calcCreditsTotal(state, val) {
            if (val) {
                state.user.credit_amount = val.credit_amount;
                state.user.credit_amount_taxable = val.credit_amount_taxable;
            }
            const totalCredits = +state.user.credit_amount + +state.user.credit_amount_taxable;
            if (totalCredits !== state.user?.total_credits) {
                state.user.total_credits = totalCredits;
                this.commit("cart/saveCart");
            }
        },

    },
    actions: {
        async register({state}, payload) {
            if (payload.email_confirmation !== payload.email) {
                throw new Error("Email and confirmation do not match");
            }
            const res = await axios.post("/user", {user: payload, referral_code: state.referralCode});
            afterLogin(this, state, res, true);
            return res;
        },

        async login({state}, payload) {
            const res = await axios.post("/users/sign_in", {user: payload});
            afterLogin(this, state, res);
            return res.data;
        },

        async getUserData({commit}) {
            try {
                const params = {user_id: router.currentRoute.value.query?.id};
                const res = await axios.get("/user", {params});
                commit("setUser", res.data.user);
                return res.data.user;
            } catch (e) {
                app.config.globalProperties.$toastMsg(error_handler(e).error);
                return router.push({name: "Home"});
            }
        },

        async logout({commit}) {
            app.config.globalProperties.$customEvent("_sf_user_logout");
            commit("setResponseToken", await axios.get("/users/sign_out"));
            commit("setUser", null);
            this.commit("songBuilder/resetSongData");
            this.commit("cart/resetCart", true);
            localStorage.removeItem("sf_addresses");
            if (router.currentRoute.value.matched.find(r => r.meta?.requireAuth)) {
                return router.push({name: "Home"});
            }
            return true;
        },

        async updateAccountInformation({commit}, payload) {
            const res = await axios.put("/user", {user: payload});
            commit("setUser", res.data.user);
            app.config.globalProperties.$toastMsg("Updated!");
            return res.data;
        },

        async updatePassword(store, payload) {
            if (payload.new_password !== payload.new_password_confirm) {
                return {error: "Passwords Don't Match."};
            }
            const res = await axios.put("/users/password", {user: payload});
            app.config.globalProperties.$toastMsg("Password Updated!");
            return res.data;
        },

        async loadAddresses(store, payload) {
            let savedAddresses = localStorage.getItem("sf_addresses");
            savedAddresses &&= JSON.parse(savedAddresses);
            const res = await axios.get("/addresses", {params: {...savedAddresses, ...payload}});
            return res.data;
        },

        async saveAddress(store, payload) {
            const res = await axios.post("/addresses", payload);
            return res.data;
        },

        async validateReferralCode({commit, state}) {
            const code = router.currentRoute.value.params.code || state.referralCode;
            if (!code) return;
            try {
                const res = await axios.get("/user/referral_user", {params: {referral_code: code}});
                commit("setReferralCode", code);
                return res.data;
            } catch (e) {
                const error = error_handler(e).error;
                app.config.globalProperties.$swal({
                    icon: "warning",
                    html: error,
                });
                commit("setReferralCode", null);
                return router.push({name: "Home"});
            }
        },

        async loadCreditBalance({commit}, params) {
            try {
                const res = await axios.get("/dashboard/credit_balance", {params});
                commit("calcCreditsTotal", res.data);
                return res.data;
            } catch (e) {
                const res = error_handler(e);
                app.config.globalProperties.$toastMsg(res.error);
                return error_handler(e);
            }
        },

        async addGiftCard({commit}, params) {
            const res = await axios.post("/dashboard/apply_gift_card", params);
            commit("calcCreditsTotal", res.data);
            return res.data;
        }
    }
};

function afterLogin(store, state, res, registration = false) {
    store.commit("auth/setUser", res.data.user);
    store.commit("auth/setResponseToken", res);
    app.config.globalProperties.$customEvent("_sf_user_login", {registration});
    store.commit("auth/redirectToUserPage");
    if (state.redirectQuery?.afterLoginCallback) {
        state.redirectQuery.afterLoginCallback();
    } else {
        app.config.globalProperties.$swal({
            html: `<h4>Success</h4>`,
            showConfirmButton: false,
            timer: 1500,
            icon: "success"
        });
    }
}
