<template>
    <div class="songBuilderSlide mx-w375 p-20 bgColor-beige mt-16 mb-48">
        <component :is="questions[step.question]" :step="step"/>
    </div>
</template>

<script>
    import {shallowRef} from "vue";
    import InputText from "./fields/InputText";
    import InputEmail from "../song_builder/fields/InputEmail";
    import StudiosButton from "./fields/StudiosButton.vue";
    import ButtonMultiple from "../song_builder/fields/ButtonMultiple";

    export default {
        name: "SongBuilderSlide",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                questions: {
                    for_someone_else: shallowRef(StudiosButton),
                    recipient: shallowRef(InputText),
                    gifter_email: shallowRef(InputEmail),
                    genre: shallowRef(StudiosButton),
                    moods: shallowRef(ButtonMultiple),
                }
            };
        }
    };
</script>
