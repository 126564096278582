import app from "@songfinch/studios/brachs/app.js";
import {init, setTag} from "@sentry/vue";
import store from "@songfinch/studios/brachs/store";
import Cookies from "js-cookie";
import {merge} from "lodash-es";

if (window.appSettings.sentry_dns) {
    const js_hash = window.appSettings.version;
    init({
        app,
        dsn: window.appSettings.sentry_dns,
        //release: window.appSettings.release_version, //???
        normalizeDepth: 10,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: window.appSettings.env === "production" ? 1.0 : 0.25, // ???
        ignoreErrors: [
            /^AbortError:/,
            /^Illegal invocation/, // Some TikTok pixel error
            /^Ignore:/,
            /^Blocked a frame with origin/,
            /^ResizeObserver loop/,
            /^Request aborted/,
            /AutoFillPopupClose/, // caused by Chrome's autofill
            /sentry-browser\.min\.js/ // excluding error everytime when zendesk can't load sentry-browser.min.js cuz of cache

        ],
        beforeSend(event) {
            if (!window.appSettings.sentry_dns) { //We can clear this value if we want to stop error tracking
                return null;
            }
            // UserID Resolution
            // * User (external user id)
            // * Cookie (segment user id)
            // * Cookie (segment anonymous user id)
            // * Cookie (CIO anonymous user id)
            const id = store.state.auth.user?.external_user_id ||
                Cookies.get("ajs_user_id") ||
                Cookies.get("ajs_anonymous_id") ||
                Cookies.get("_cioanonid");
            merge(event, {
                extra: {
                    js_hash,
                }
            });
            event.user = {id};
            return event;
        }
    });
    setTag("sf_pack", "studios_v1");
    setTag("sf_origin", "frontend");
    setTag("js_hash", js_hash);
}

