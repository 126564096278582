import {createRouter, createWebHistory} from "vue-router/dist/vue-router.cjs";
import store from "@songfinch/studios/brachs/store";
import Redeem from "@songfinch/studios/brachs/views/Redeem";
import SongBuilder from "@songfinch/studios/brachs/views/SongBuilder";
import Story from "@songfinch/studios/brachs/views/Story";
import StoryBuilder from "@songfinch/studios/brachs/views/StoryBuilder";
import ReviewPage from "@songfinch/studios/brachs/views/ReviewPage";
import app from "@songfinch/studios/brachs/app.js";
import axios from "axios";

const builderGuard = () => {
    if (!store.state.cart.cart.coupon?.code) return {name: "Redeem"};
};

const routes = [
    {
        path: "/",
        name: "Redeem",
        component: Redeem
    },
    {
        path: "/song-builder",
        name: "SongBuilder",
        component: SongBuilder,
        meta: {fixHeader: true, skipHashScroll: true},
        beforeEnter: [builderGuard]
    },
    {
        path: "/story-builder",
        name: "StoryBuilder",
        component: StoryBuilder,
        meta: {fixHeader: true},
        beforeEnter: [builderGuard]
    },
    {
        path: "/review",
        name: "ReviewPage",
        component: ReviewPage,
        meta: {fixHeader: true},
        beforeEnter: [builderGuard]
    },
    {
        path: "/stories/:slug",
        name: "Story",
        component: Story
    }
];

const router = createRouter({
    history: createWebHistory(`/studios/${window.appSettings.affiliate}`),
    routes,
    scrollBehavior(to, from) {
        //Scroll to element or to top with delay
        return new Promise(resolve => setTimeout(() => {
            let scrollTo;
            if (to.hash && !to.meta.skipHashScroll) {
                scrollTo = {el: to.hash, top: 200};
            } else if (to.name !== from.name || !to.meta.skipHashScroll) {
                scrollTo = {top: 0};
            }
            resolve(scrollTo);
        }, 400));
    }
});


router.afterEach(async to => {
    //Hook after each route change
    app.config.globalProperties.$flushGTMScripts();
    try {
        const res = await axios.get(`/cms/track?current_path=${to.path}`);
        app.config.globalProperties.$customEvent("_sf_pageview", {
            request_id: res.headers["x-request-id"]
        });
    } catch (e) {
        //empty for now
    }
});

router.beforeEach((to, from, next) => {
    //Hook before each route change
    //Next is required otherwise it wont render
    next();
});

export default router;
