<template>
    <div id="app" :class="appClass">
        <StikyHeader/>
        <AudioPlayer>
            <template #songDetails="{audio}">
                <div class="frameBox me-20">
                    <RoundImageWithDefault
                        :image="audio.image"
                        :alt="audio.name"
                    />
                </div>
                <div style="min-width: 0;">
                    <div class="h5 mb-3px playerMinified" v-text="audio.name"/>
                    <div v-if="audio.song_title" class="p3 mt-3px mt-md-0" v-text="audio.song_title"/>
                    <div v-if="audio.song_subtitle" class="p4" v-text="audio.song_subtitle"/>
                </div>
            </template>
        </AudioPlayer>
        <router-view v-if="cms" v-slot="{Component}">
            <transition name="custFade">
                <component :is="Component"/>
            </transition>
        </router-view>
        <Modal/>
    </div>
</template>

<script>
    import RoundImageWithDefault from "@songfinch/shared/components/RoundImageWithDefault";
    import Modal from "@songfinch/shared/plugins/modal/Modal";
    import StikyHeader from "@songfinch/studios/brachs/components/shared/StikyHeader";

    export default {
        name: "App",
        components: {Modal, StikyHeader, RoundImageWithDefault},
        data() {
            return {
                appClass: this.$route.matched?.[0]?.name,
            };
        },
        computed: {
            cms() {
                return this.$store.state.cms.data;
            }
        },
        watch: {
            $route(to) {
                this.appClass = to.matched?.[0]?.name;
            }
        },
        created() {
            document.body.setAttribute("data-theme", "studios");
        }
    };
</script>
