<template>
    <div class="mt-16 mt-md-48 text-center px-md-0 px-0 pb-40 storySongCompleted">
        <div class="container px-10 mx-w375">
            <LandingLogos/>
            <h1 class="mb-0 mt-48 h3" v-text="cms.title"/>
            <h2 class="p1" v-text="cms.subtitle"/>
            <CircleStudios class="my-16" small-size :circle-text="circleText" :gif="gif"/>
            <div class="d-flex justify-content-center mt-32 mb-20">
                <PlayButton v-if="storySong" :data="storySong"/>
            </div>
            <div class="mt-10 mb-104">
                <h3 class="m-0">
                    Got it right
                </h3>
                <div class="p1">
                    by {{story.song.artist.fullName}}
                </div>
            </div>
            <div class="lyricBox pt-56 pb-24">
                <h3 class="mb-16">
                    Lyrics
                </h3>
                <div class="px-20" v-html="story.song.lyrics"/>
            </div>
            <div v-if="story.download_link" class="mt-20">
                <a
                    :href="story.download_link"
                    class="mkButton"
                    download
                >download song</a>
            </div>
        </div>
    </div>
</template>

<script>
    import CircleStudios from "@songfinch/studios/brachs/components/song_builder/CircleStudios";
    import LandingLogos from "@songfinch/studios/brachs/components/shared/LandingLogos";

    export default {
        name: "StorySongComplete",
        components: {CircleStudios, LandingLogos},
        props: {
            story: {
                type: Object,
                required: true
            }
        },
        computed: {
            gif() {
                return this.$store.state.cms.data?.songBuilder?.circle;
            },
            cms() {
                return this.$store.state.cms.data?.songComplete.story_song_complite;
            },
            circleText() {
                return `Song for ${this.story.recipient}`;
            },
            storySong() {
                const song = this.story.song;
                return {
                    image: song.artist.avatarPublicID,
                    name: song.artist.fullName,
                    audio: song.audioUrl,
                    genres: [song.genre],
                    occasions: [],
                    song_title: song.title,
                    story_slug: this.story.slug,
                    song_artist_id: song.artist.id,
                    media_type: "story",
                    player_type: "audio",
                };
            }
        }
    };
</script>
