<template>
    <div class="redeemPage page pb-270">
        <div class="container mx-w400 px-10 mt-48 mt-md-80 text-center">
            <LandingLogos class="mb-48"/>
            <transition name="custFade">
                <div v-if="!cart.coupon?.code || delayingActivation > 0">
                    <h1 class="h3 m-0" v-text="cms.code_page.title"/>
                    <p class="p3" v-text="cms.code_page.subtitle"/>
                    <FormAjax class="mt-32 redeemForm mx-10" :submit-action="codeActivate">
                        <div class="l8 mb-8">
                            Your Code
                        </div>
                        <div class="form-group position-relative">
                            <input v-model="code" required type="text" class="form-control">
                            <FormButton
                                button-always-active
                                wrap-class="d-contents"
                                class="mkButton short beige noMinWidth"
                            >
                                Redeem
                            </FormButton>
                        </div>
                        <FormError/>
                    </FormAjax>
                    <div v-if="cms.code_page.need_help_link_text" class="mt-16">
                        <a
                            target="_blank"
                            :href="cms.code_page.need_help_link"
                            class="l5 !@decoration-1 text-decoration-underline"
                            v-text="cms.code_page.need_help_link_text"
                        />
                    </div>
                </div>
                <div v-else>
                    <h1 class="mb-0 h3" v-html="cms.code_active.title"/>
                    <p class="p3" v-text="cms.code_active.subtitle"/>
                    <div class="mt-32">
                        <p>
                            <router-link class="mkButton" :to="{name: 'SongBuilder'}" v-html="cms.code_active.button_label"/>
                        </p>
                        <a href="#" class="l5 !@decoration-1 text-decoration-underline" @click.prevent="learnmore">
                            Learn More
                        </a>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import CustomSongInfo from "@songfinch/studios/brachs/components/popups/CustomSongInfo";
    import confetti from "canvas-confetti";
    import LandingLogos from "@songfinch/studios/brachs/components/shared/LandingLogos";
    import {FormAjax, FormButton, FormError} from "@songfinch/shared/plugins/form_ajax/index";

    export default {
        name: "Redeem",
        components: {LandingLogos, FormAjax, FormButton, FormError},
        data() {
            return {
                code: "",
                mailSubject: "",
                mailBody: "",
                delayingActivation: 2000
            };
        },
        computed: {
            cms() {
                return this.$store.state.cms.data?.redeem;
            },
            cart() {
                return this.$store.state.cart.cart;
            }
        },
        mounted() {
            if (this.delayingActivation > 0) {
                setTimeout(() => {
                    this.delayingActivation = 0;
                    this.onActivationReady();
                }, this.delayingActivation);
            }
        },
        methods: {
            fireConfetti() {
                [
                    {ratio: 0.25, options: {spread: 26, startVelocity: 55}},
                    {ratio: 0.2, options: {spread: 26}},
                    {ratio: 0.34, options: {spread: 100, decay: 0.91, scalar: 0.8}},
                    {ratio: 0.1, options: {spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2}},
                    {ratio: 0.1, options: {spread: 120, startVelocity: 45}},
                ].forEach(o => confetti({
                    particleCount: Math.floor(200 * o.ratio),
                    origin: {y: 0.7},
                    colors: ["#F64D1C", "#F40000", "#EA3CF6"],
                    ...o.options
                }));
            },
            onActivationReady() {
                //If we have a coupon stored then it was valid, lets fire the confetti
                if (this.cart.coupon?.code) {
                    this.fireConfetti();
                    this.$customEvent("_sf_apply_coupon");
                }
            },
            async codeActivate() {
                await this.$store.dispatch("cart/loadCoupon", this.code);
                //Check to see if coupon is valid, if so then reset code
                if (!this.cart.coupon?.code) {
                    this.code = "";
                }
                this.fireConfetti();
                this.$customEvent("_sf_apply_coupon");
            },
            learnmore() {
                this.$store.commit("shared/showModal", {
                    component: CustomSongInfo,
                    props: {
                        title: this.cms.popup.title,
                        content: this.cms.popup.content,
                    },
                    slide: "bottom",
                    swalSettings: {
                        width: 820,
                    }
                });
            },
        },
    };
</script>
