import axios from "axios";
import store from "@songfinch/studios/brachs/store";

axios.defaults.baseURL = window.location.origin + `/v3/api/`;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common["SF-FE-App-Name"] = "studios";
axios.defaults.headers.common["SF-FE-App-V"] = window.appSettings.version;
axios.defaults.headers.common["SF-FE-Data-V"] = window.appSettings.data_version;

axios.interceptors.response.use(
    response => response,
    error => {
        const res = error?.response || {};
        if (res.status === 401) {
            location.href = `/login`;
        } else if (res.status === 503) {
            location.reload();
        } else if (res.status === 426 && res.data?.sf_refresh) {
            window.appSettings.sentry_dns = null; // Clearing to stop any sentry errors
            if (res.data.sf_clear_data) {
                localStorage.clear();
            }
            location.reload();
        } else if (res?.status === 422 && res.data.invalid_token && !res.config.sf_repeat) {
            //If token expired/invalid take new one and repeat request
            res.config.headers["X-CSRF-Token"] = res.headers["x-csrf-token"];
            res.config.sf_repeat = true;
            store.commit("auth/setUser", res.data.user);
            store.commit("auth/setResponseToken", res);
            return axios.request(res.config);
        }
        return Promise.reject(error);
    }
);
