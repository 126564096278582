<template>
    <div>
        <h2 v-text="step.title"/>
        <div class="p3 mb-auto" v-html="step.content"/>
    </div>
    <div class="mt-auto w-100">
        <div class="form-group l8">
            {{step.input_label}}
            <input
                v-email-validity
                type="email"
                class="form-control"
                required
                :value="emailInput"
                @input="onInput"
            >
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputEmail",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                emailInput: "" //need for circle text update
            };
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            }
        },
        created() {
            this.songData[this.step.question] ||= this.$store.state.auth?.user?.email;
            this.emailInput = this.songData[this.step.question];
            this.generateAnswerData(this.emailInput);
            this.$watch(() => this.emailInput, this.generateAnswerData);
        },
        methods: {
            onInput(e) {
                this.emailInput = e.target.value;
                //Only store in state if we have a valid email
                this.songData[this.step.question] = e.target.checkValidity() ? e.target.value : "";
            },
            generateAnswerData(val, oldVal) {
                if (val) {
                    this.$store.commit("songBuilder/setTextCircle", this.step.circle_placeholder_text.replace("[value]", val));
                } else if (oldVal) {
                    this.$store.commit("songBuilder/setTextCircle", null);
                }
            }
        }
    };
</script>
