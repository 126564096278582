import {createStore} from "vuex";
import shared from "./shared";
import auth from "./auth";
import cms from "./cms";
import songBuilder from "./song_builder";
import cart from "./cart";

export default createStore({
    modules: {
        auth,
        shared,
        cms,
        songBuilder,
        cart
    }
});
