<template>
    <FormAjax ref="slideForm" :submit-action="complete" class="w-100 mb-auto storyBuilderPage page mobileContainerForDesktop">
        <StoryHeader/>
        <div class="pt-16 container px-32">
            <h3 class="mb-0" v-text="cms.story_content.title_message"/>
            <div class="mb-0 p3 pb-16" v-text="cms.story_content.content_message"/>
            <TextareaWithLimit
                id="message_question"
                v-model="songData.message_question"
                wrapper-class="mt-0 pt-8"
                :maxlength="maxLength"
                :minlength="minLength"
                required
            />
        </div>
        <div class="w-100 my-32 my-md-0 mt-md-40 overflow-hidden">
            <hr class="bgColor-dark-grey d-md-none m-0">
        </div>
        <div class="w-100">
            <h3 class="mb-0 px-32" v-text="cms.story_content.title_addon"/>
            <div class="mb-0 p3 pb-16 px-32" v-text="cms.story_content.content_addon"/>
            <div class="spliderTextContainer" :class="{firstSlide: isFirst, lastSlide: isLast, onFocus: isFocused}">
                <Slider
                    v-bind="sliderOptions"
                    ref="slider"
                    class="mb-0"
                    :arrows="true"
                    :custom-arrow="$cld.url('static/coca-cola/sliderArrow', {fetch_format: null})"
                >
                    <SplideSlide v-for="(question, i) in songData.questions" :key="i" :data-index="i">
                        <div class="px-8 px-md-0 mx-w375-md">
                            <div class="l8 pt-8" v-text="question.question"/>
                            <TextareaWithLimit
                                :id="question.key"
                                v-model="question.answer"
                                class="mb-8"
                                :maxlength="maxLength"
                                :minlength="minLength"
                                data-slide-text="1"
                                @focus="isFocused = true"
                                @blur="isFocused = false"
                            />
                        </div>
                    </SplideSlide>
                </Slider>
            </div>
        </div>
        <div class="mt-auto w-100">
            <RequiredFormHelper v-if="answeredQuestions" message="Please complete at least 2 prompts above"/>
            <FormError class="container text-center"/>
            <div class="my-40 d-flex align-items-center px-32 w-100">
                <router-link class="mkButton beige invertBorder noHoverMob noMinWidth me-8" :to="{name: 'SongBuilder'}">
                    Back
                </router-link>
                <FormButton wrap-class="d-inline-block" class="mkButton noHoverMob noMinWidth position-relative" @click="validateForm">
                    review everything
                </FormButton>
            </div>
        </div>
    </FormAjax>
</template>

<script>
    import StoryHeader from "@songfinch/studios/brachs/components/story_builder/StoryHeader";
    import sliderValidity from "@songfinch/studios/brachs/helpers/slider_validity";
    import {FormAjax, FormButton, FormError} from "@songfinch/shared/plugins/form_ajax/index";

    export default {
        name: "StoryBuilder",
        components: {StoryHeader, FormAjax, FormButton, FormError},
        mixins: [sliderValidity],
        data() {
            return {
                isLast: false,
                isFirst: true,
                isFocused: false,
                sliderOptions: {
                    options: {
                        trimSpace: true,
                        rewind: false,
                        pagination: false,
                        padding: {left: 30, right: 185},
                        drag: false,
                        breakpoints: {
                            1199: {
                                drag: true,
                            },
                            767: {
                                trimSpace: false,
                                focus: "center",
                                padding: 24,
                            }
                        }
                    },
                    events: {
                        "splide:move": (splide, newIndex) => {
                            this.isLast = newIndex === this.songData.questions?.length - 1;
                            this.isFirst = newIndex === 0;
                        },
                        "splide:dragging": () => {
                            this.$refs.slider.$el.querySelector(":focus")?.blur();
                        }
                    }
                }
            };
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            },
            cms() {
                return this.$store.state.cms.data?.storyBuilder;
            },
            maxLength() {
                return this.cms?.max_length || 500;
            },
            minLength() {
                return this.cms?.min_length || 200;
            },
            answeredQuestions() {
                return this.songData.questions.filter(q => q.answer).length < 2;
            }
        },
        created() {
            this.songData.questions = this.cms.questions.map(q => {
                const question = {...q};
                question.answer = this.songData.questions?.find(a => a.key === q.key)?.answer || "";
                return question;
            });
        },
        mounted() {
            if (this.$route.hash) {
                const editField = this.$refs.slideForm.$el.querySelector(this.$route.hash);
                if (editField) {
                    setTimeout(() => {
                        if (editField?.dataset.slideText) {
                            this.gotoSlideWithError(this.$refs.slider, editField);
                        } else {
                            editField.focus();
                        }
                    }, 300);

                }
            }
        },
        methods: {
            removeEmpty() {
                this.songData.questions = this.songData.questions.filter(q => q.answer?.trim());
            },
            validateForm(e) {
                const firstInvalid = this.$refs.slideForm.$el.querySelector(":invalid");
                if (firstInvalid?.dataset.slideText) {
                    e.preventDefault();
                    return this.gotoSlideWithError(this.$refs.slider, firstInvalid);
                }
            },
            complete() {
                this.removeEmpty();
                this.$store.commit("songBuilder/saveSongData");
                this.$customEvent("_sf_songbuildersubmit", "questions");
                this.$router.push({name: "ReviewPage"});
            }
        }
    };
</script>
