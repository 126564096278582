<template>
    <div class="px-20 px-md-40 py-56 text-start">
        <h1 class="mb-10 color-black h3" v-text="title"/>
        <div v-html="content"/>
    </div>
</template>

<script>
    export default {
        name: "CustomSongInfo",
        props: {
            title: {
                type: String,
                default: ""
            },
            content: {
                type: String,
                default: ""
            }
        }
    };
</script>
