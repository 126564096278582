<template>
    <div>
        <h3 class="m-0" v-text="cmsStoryBuilder.story_content.title_message"/>
        <div class="mb-0 p3 pb-16" v-html="cmsStoryBuilder.story_content.content_message"/>
        <div class="p1 color-medium-grey quoteBox" v-text="songData.message_question"/>
        <router-link :to="{name: 'StoryBuilder', hash: '#message_question'}" class="l5 mt-16 mkButton beige noMinWidth short">
            Edit
        </router-link>
        <!--    List    -->
        <h3 class="mb-0 mt-32">
            Your song details
        </h3>
        <div v-for="item in songData.questions" :key="item.key" class="mb-32">
            <div class="p1 mb-16 firstCharUp" v-text="item.question"/>
            <div class="p1 color-medium-grey quoteBox" v-text="item.answer"/>
            <router-link :to="{name: 'StoryBuilder', hash: `#${item.key}`}" class="l5 mt-16 mkButton beige noMinWidth short">
                Edit
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SavedStoryData",
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            },
            cmsStoryBuilder() {
                return this.$store.state.cms.data?.storyBuilder;
            }
        }
    };
</script>
