<template>
    <div class="mt-16 mt-md-48 text-center container pb-270 px-10 storyInProgress">
        <div class="mx-w375">
            <LandingLogos/>
            <h1 class="mb-0 mt-48 h3" v-text="cms.title"/>
            <h2 class="p1" v-text="cms.subtitle"/>

            <div class="my-24 position-relative">
                <CircleStudios class="my-8" small-size :circle-text="circleText" :gif="gif"/>
                <div v-if="nextStatus" class="position-absolute statuser">
                    <span v-for="(el, i) in tempStatuses" :key="i" :class="{active: i === curentIndex, visited: curentIndex > i}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="31" viewBox="0 0 75 31" fill="none">
                            <path
                                opacity="0.2"
                                d="M73.8594 5.5015C74.1719 4.51694 73.6112 3.466 72.6113 3.20679C59.7082 -0.138156 46.0846 0.305882 33.3857 4.52772C20.7195 8.73872 9.56668 16.5108 1.24156 26.8755C0.59469 27.6808 0.77313 28.8585 1.61204 29.4612C2.41925 30.0411 3.53942 29.8714 4.163 29.0974C12.0405 19.3198 22.5789 11.987 34.5432 8.0094C46.5385 4.02149 59.4049 3.5929 71.5965 6.73413C72.559 6.98212 73.5587 6.44886 73.8594 5.5015Z"
                                fill="black"
                                stroke="#F4F2EB"
                            />
                        </svg>
                    </span>
                </div>
            </div>
            <div class="mb-16 l5 color-red lineHeightNormal">
                <strong class="d-block" v-text="story.song_status"/>
                {{nextStatus}}
            </div>
        </div>
        <div class="p3 mb-24 px-10 px-md-0 mx-w800-md" v-html="cms.content"/>
        <p class="l8 px-10 mx-w375" v-text="cms.alert"/>
    </div>
</template>

<script>
    import CircleStudios from "@songfinch/studios/brachs/components/song_builder/CircleStudios";
    import LandingLogos from "@songfinch/studios/brachs/components/shared/LandingLogos";

    export default {
        name: "StoryInProgress",
        components: {CircleStudios, LandingLogos},
        props: {
            story: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                tempStatuses: ["Submission Received", "Artist Review", "Writing & Producing", "Recording & Mixing", "Finishing Touches"],
            };
        },
        computed: {
            gif() {
                return this.$store.state.cms.data?.songBuilder?.circle;
            },
            cms() {
                return this.$store.state.cms.data?.songComplete.story_in_progress;
            },
            circleText() {
                return `Song for ${this.story.recipient}`;
            },
            curentIndex() {
                return this.tempStatuses.findIndex(el => el === this.story.song_status);
            },
            nextStatus() {
                if (this.curentIndex === -1) return;
                const nextIndex = this.curentIndex + 1;
                const diffToLast = this.tempStatuses.length - this.curentIndex - 1;
                if (diffToLast === 1) return "Last Step: " + this.tempStatuses[nextIndex];
                if (diffToLast === 0) return "Your Song is Almost Ready!";
                return "Next step: " + this.tempStatuses[nextIndex];
            }
        }
    };
</script>
