<template>
    <div>
        <div class="d-flex align-items-center justify-content-center">
            <img :src="$cld.url('static/coca-cola/sf_logo', {fetch_format: null})" alt="logo">
            <span class="d-inline-block mx-10 bgColor-black" style="width: 2px; height: 42px"/>
            <img :src="$cld.url('static/coca-cola/coca-cola-logo', {fetch_format: null})" style="max-width: 48px" alt="logo">
        </div>
    </div>
</template>

<script>
    export default {
        name: "LandingLogos"
    };
</script>
