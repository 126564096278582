<template>
    <div>
        <div>
            <h2 v-text="step.title"/>
            <div class="p3 mb-16" v-html="content"/>
        </div>
        <div class="w-100 d-flex flex-wrap gap-8">
            <button
                v-for="(item) in stepOptions"
                :key="item.option.id"
                class="mkButton short beige noMinWidth noHoverMob"
                :class="{active: allIds?.includes(item.option.id)}"
                @click.prevent="selectItem(item.option)"
                v-text="item.option.text"
            />
        </div>
        <RequiredFormHelper v-if="!songData[step.question].length" message="Please select an option"/>
    </div>
</template>

<script>
    export default {
        name: "ButtonMultiple",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                stepOptions: this.step[this.step.question],
                content: null
            };
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            },
            allIds() {
                return this.songData[this.step.question].map(q => q.id);
            }
        },
        watch: {
            content() {
                //Triggering so slider can resize if content change
                window.dispatchEvent(new Event("resize"));
            }
        },
        created() {
            this.songData[this.step.question] ??= [];
            this.generateAnswerData(this.songData[this.step.question], false);
            this.$watch(
                () => this.songData[this.step.question],
                this.generateAnswerData,
                {deep: true}
            );
        },
        methods: {
            selectItem(item) {
                const max = 2;
                const sData = this.songData[this.step.question];
                const index = sData.findIndex(obj => obj.id === item.id);
                if (index !== -1) {
                    return sData.splice(index, 1);
                }
                if (this.songData[this.step.question].length < max) {
                    return this.songData[this.step.question].push(item);
                }
                this.songData[this.step.question][max - 1] = item;
            },
            generateAnswerData(val, oldVal) {
                const ids = this.songData[this.step.question].map(s => s.id);
                if (!ids.length) {
                    if (oldVal !== false) this.$store.commit("songBuilder/setTextCircle", null);
                    return this.content = this.step.content;
                }

                const optionIndexes = [];
                this.stepOptions.forEach((o, i) => ids.includes(o.option.id) && optionIndexes.push(i));
                if (!optionIndexes.length) {
                    this.songData[this.step.question] = [];
                    return this.content = this.step.content;
                }

                const content = optionIndexes.map(i => this.stepOptions[i].content_below).join(" ");
                this.content = content || this.step.content;

                if (optionIndexes.length) {
                    let result = this.songData[this.step.question].map(q => q.text).join(" & ");
                    result = this.step.circle_placeholder_text.replace("[value]", result);
                    this.$store.commit("songBuilder/setTextCircle", result);
                } else {
                    this.$store.commit("songBuilder/setTextCircle", null);
                }
            }
        }
    };
</script>
