import app from "./app.js";
import router from "./router";
import store from "./store";

import "./helpers/check_version";
import "./helpers/sentry";
import "./helpers/gtm_tag_flush";
import "./listeners/events";

import "./helpers/axios_api_init";
import "vue2-animate/dist/vue2-animate.min.css"; // For animation transition

import validity_directives from "@songfinch/shared/mixins/validity_directives";
app.mixin(validity_directives);

import RequiredFormHelper from "@songfinch/shared/components/RequiredFormHelper";
app.component("RequiredFormHelper", RequiredFormHelper);

import TextareaWithLimit from "@songfinch/shared/components/TextareaWithLimit";
app.component("TextareaWithLimit", TextareaWithLimit);

import sfHelper from "@songfinch/shared/plugins/sf_helpers";
app.use(sfHelper);

import sfAnalytics from "@songfinch/shared/plugins/sf_analytics";
app.use(sfAnalytics);

import CloudinaryPlugin from "@songfinch/shared/plugins/cloudinary";
app.use(CloudinaryPlugin);

import AudioPlayerPlugin from "@songfinch/shared/plugins/audio_player";
app.use(AudioPlayerPlugin);

import ToastMsg from "@songfinch/shared/plugins/toast_msg";
app.use(ToastMsg);

import Slider from "@songfinch/shared/plugins/slider";
app.use(Slider);

import MkCheckbox from "@songfinch/shared/components/MkCheckbox";
app.component("MkCheckbox", MkCheckbox);

import Modal from "@songfinch/shared/plugins/modal/plugin";
import {initUUID} from "@songfinch/shared/helpers/uuid";
app.use(Modal, {
    customClass: {
        confirmButton: "mkButton mx-10 mb-20 noLoading",
        cancelButton: "mkButton mx-10 dark mb-20 noLoading",
    },
    showClass: {
        popup: "fadeIn"
    },
    hideClass: {
        popup: "fadeOut",
    },
    width: 575,
    background: "var(--sfc-beige)",
    iconColor: "var(--sfc-red)",
    buttonsStyling: false
});

/*store.dispatch("cms/loadCMSData", {
    queryParams: {group: `/studios/${window.appSettings.affiliate}`},
}).then(() => {
    //TODO: update cms to return 404
    if (!store.state.cms.data) {
        location.href = "/404";
    }
});*/

const initVue = () => {
    store.commit("songBuilder/initSongData");
    store.dispatch("cart/initCart")
        .then(() => {
            store.commit("auth/setUser", window.appSettings.user);
        })
        .finally(async () => {
            const el = document.body.appendChild(document.createElement("app"));
            await initUUID();
            app.use(router);
            app.use(store);
            app.mount(el);
        });
};

initVue();
