import axios from "axios";

export default {
    namespaced: true,
    state: {
        data: null,
        __headers: null,
    },
    actions: {
        async loadCMSData({state}, payload) {
            state.data = null;
            const res = await axios.get("/cms", {
                params: payload.queryParams,
                baseURL: "/v3/api",
                ...payload.axiosParams
            });
            const keys = Object.keys(res.data || {});
            let data;
            if (keys.length) {
                data = keys.reduce((val, k) => {
                    val[k.split(".")[1]] = res.data[k];
                    return val;
                }, {});
            }
            state.__headers = res.headers;
            state.data = data;
            return data;
        },
        async getCmsFields({dispatch}, payload) {
            return await dispatch("loadCMSData", {
                queryParams: payload.queryParams,
                axiosParams: {
                    cache: {ignoreCache: false},
                    ...payload?.axiosParams
                }
            });
        },
    }
};
