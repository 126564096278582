<template>
    <div :class="{show: $route.meta.fixHeader}" class="stikyHeader">
        <div class="bgColor-white text-center w-100">
            <div class="py-8 mx-w400 d-flex align-items-center justify-content-between px-16">
                <a href="#" @click.prevent="$store.commit('songBuilder/slideBack')">
                    <img :src="backIc" alt="back">
                </a>
                <div class="d-flex align-items-center justify-content-center">
                    <img class="d-block d-md-none" :src="$cld.url('static/coca-cola/sf_sm_logo', {fetch_format: null})" alt="Songfinch">
                    <img class="d-none d-md-block" :src="$cld.url('static/coca-cola/sf_logo', {fetch_format: null})" alt="Songfinch">
                    <span class="d-inline-block mx-10 bgColor-black" style="width: 2px; height: 42px"/>
                    <img :src="$cld.url('static/coca-cola/coca-cola-logo', {fetch_format: null})" style="max-width: 48px" alt="logo">
                </div>
                <div/>
            </div>
        </div>
    </div>
</template>

<script>
    import backIc from "@songfinch/studios/brachs/assets/images/back_ic.svg";
    export default {
        name: "StikyHeader",
        data() {
            return {
                backIc,
            };
        }
    };
</script>
